import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

import type { SpartacuxCoreState } from '../../core.state';
import type { PageStatusCode } from '../interfaces/PageStatusCode';
import { SET_PAGE_STATUS_CODE } from '../page.actions';
import { statusCodeSelector } from '../page.selectors';

/**
 * Allows to set the status code of a page.
 */
export function usePageStatusCode(
  statusCode: PageStatusCode | undefined,
): void {
  const dispatch = useDispatch();
  const store = useStore<SpartacuxCoreState>();

  useEffect(() => {
    if (statusCode === undefined) {
      return;
    }
    const pageStatusCode = statusCodeSelector(store.getState());

    if (pageStatusCode !== statusCode) {
      dispatch({ type: SET_PAGE_STATUS_CODE, statusCode });
    }
  }, [dispatch, statusCode, store]);
}
