import classnames from 'classnames';
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ComponentType,
  type CSSProperties,
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactElement,
  type RefAttributes,
} from 'react';

import styles from './Text.module.scss';

export type TextType =
  | 'display'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'body1'
  | 'body2'
  | 'small'
  | 'tiny';

export type TextVariant = 'none' | 'primary' | 'primaryDark';

export type TextWeight = 'bold' | 'semiBold' | 'regular';

interface TextBaseProps<Element extends ElementType> {
  /**
   * To override the style
   */
  className?: string;
  /**
   * Defines the color for Text
   */
  // eslint-disable-next-line react/require-default-props
  color?: string;
  /**
   * To override the container style
   */
  style?: CSSProperties;
  /**
   * To define the tag to be rendered as any HTML tag
   */
  tag?: Element;
  /**
   * To define the font-size and line-height
   */
  type?: TextType;
  /**
   * Define the variant for text: primary (n0), primaryDark (n800) or none (no default color)
   */
  variant?: TextVariant;
  /**
   * To define the font weight
   */
  weight?: TextWeight;
}

/** Props that won't be forwarded to TextProps.tag element. */
type TextOwnProps = 'color' | 'tag' | 'type' | 'variant' | 'weight';

/** @deprecated Use `core/tamagoshiv2/components/Typography/Typography` instead */
export type TextProps<Element extends ElementType> = TextBaseProps<Element> &
  Omit<ComponentPropsWithoutRef<Element>, TextOwnProps>;

function TextComponent<Element extends ElementType>(
  {
    children,
    className,
    color,
    style,
    tag: Tag = 'div' as Element,
    type = 'body2',
    variant = 'primaryDark',
    weight = 'regular',
    ...restProps
  }: TextProps<Element>,
  ref: ForwardedRef<Element>,
): ReactElement {
  return (
    <Tag
      ref={ref}
      className={classnames(
        styles.root,
        styles[type],
        styles[weight],
        styles[variant],
        className,
      )}
      style={{ color, ...style }}
      {...(restProps as any)}
    >
      {children}
    </Tag>
  );
}

export const Text = forwardRef(TextComponent) as <
  Element extends ElementType = 'div',
>(
  props: TextProps<Element> & RefAttributes<ElementRef<Element>>,
) => ReactElement;

(Text as ComponentType).displayName = 'Text';
