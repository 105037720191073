import classnames from 'classnames';
import Head from 'next/head';
import type { FunctionComponent } from 'react';

import { Text } from '@/core/tamagoshi/Text/Text';
import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';
import { usePageStatusCode } from '@/domains/core/page/hooks/usePageStatusCode';
import { Link } from '@/domains/core/routing/components/Link';
import { routes } from '@/domains/core/routing/routes/routes.default';
import { IS_B2B } from '@/domains/core/settings/constants';
import { NotFound } from '@/domains/core/tamagoshiv2/illustrations/NotFound';

import * as translations from './translations';

import styles from './Error404.module.scss';

export const Error404: FunctionComponent = () => {
  usePageStatusCode(HttpStatusCode.NOT_FOUND);

  return (
    <>
      <Head>
        <title key="meta_title">{translations.title}</title>
      </Head>
      <div className={styles.container} data-testid="error-404">
        <Text
          type="h1"
          weight="bold"
          className={classnames(styles.title, styles.titleMobile)}
        >
          {translations.title}
        </Text>
        <NotFound className={styles.illustration} />
        <div className={styles.details}>
          <Text
            type="h1"
            weight="bold"
            className={classnames(styles.title, styles.titleDesktop)}
          >
            {translations.title}
          </Text>

          <Text type="h3" weight="semiBold" className={styles.subTitle}>
            {translations.subTitle}
          </Text>
          <Text type="body1" className={styles.text}>
            {translations.text}
          </Text>
          <Link
            href={routes.home}
            className={classnames(styles.homeButton, {
              [styles.homeButtonB2b]: IS_B2B,
            })}
            data-testid="homepage-button"
          >
            {translations.button}
          </Link>
        </div>
      </div>
    </>
  );
};
