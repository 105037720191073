import { translate } from '@/domains/core/localization/translate';
export const {
  title,
  subTitle,
  text,
  button
} = translate({
  "title": "Error 404",
  "subTitle": "\xA1Vaya! Esto no estaba en nuestros planes.",
  "text": "S\xEDguenos, te llevaremos a otro sitio.",
  "button": "Volver a la p\xE1gina principal"
});